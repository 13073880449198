<template>
  <div>
    <h1>予定管理</h1>

    <v-radio-group
      v-model="radioselect"
      row
      label="取得種別:"
      @change="getData(false)"
    >
      <v-radio
        label="月処理"
        prepend-icon="mdi-toggle-switch"
        :value="RadioSelects.Month"
      ></v-radio>
      <v-radio
        label="検索処理"
        prepend-icon="mdi-toggle-switch"
        :value="RadioSelects.point"
        v-if="authcontrol()"
      ></v-radio>
    </v-radio-group>

    <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="selecthospital"
        :items="hospitals"
        outlined
        label="病院選択"
        item-text="name"
        item-value="code"
        chips
        clearable
      >
      </v-autocomplete>
      <DatePicker
        v-model="date"
        label="対象年月"
        v-if="radioselect == RadioSelects.Month"
        prepend-inner-icon="mdi-calendar"
        type="month"
        outlined
        next
        previous
      />
      <v-row>
        <v-col cols="5">
          <DatePicker
            v-model="dateFrom"
            label="対象年月From"
            v-if="radioselect == RadioSelects.point"
            prepend-inner-icon="mdi-calendar"
            type="month"
            outlined
          />
        </v-col>
        <v-col cols="1">
          <p class="range-text" v-if="radioselect == RadioSelects.point">
            〜
          </p></v-col
        >
        <v-col cols="5">
          <DatePicker
            v-model="dateTo"
            label="対象年月To"
            v-if="radioselect == RadioSelects.point"
            prepend-inner-icon="mdi-calendar"
            type="month"
            outlined
          />
        </v-col>
      </v-row>
      <v-text-field
        v-model="floor"
        v-if="radioselect == RadioSelects.point"
        label="フロア"
        outlined
        prepend-icon="mdi-transit-connection-variant"
        maxlength="255"
      ></v-text-field>
      <v-text-field
        v-model="area"
        v-if="radioselect == RadioSelects.point"
        label="エリア"
        outlined
        prepend-icon="mdi-home-map-marker"
        maxlength="255"
      ></v-text-field>
      <v-text-field
        v-model="workarea"
        v-if="radioselect == RadioSelects.point"
        label="作業箇所"
        outlined
        prepend-icon="mdi-close"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="click('query')">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!!schedule && schedule.length" class="office mt-5">
      <v-toolbar color="primary">
        <v-btn
          v-if="radioselect == RadioSelects.Month"
          class="mx-2"
          color="success"
          @click="add()"
          >スポット作業スケジュール追加</v-btn
        >
        <v-btn
          v-if="radioselect == RadioSelects.Month"
          class="mx-2"
          color="warning"
          @click="reference()"
        >
          随時スケジュール追加
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="radioselect == RadioSelects.Month"
          class="mx-2"
          color="error"
          @click="bulkInsert()"
          >スケジュール一括登録</v-btn
        >
      </v-toolbar>

      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="packagerow in affiliation.packages[n - 1]">
              <tr :key="packagerow.rowindex">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <Btn
                    color="info"
                    text
                    small
                    @click="fix(affiliation.index, packagerow.index, 'query')"
                    >編集</Btn
                  >
                </th>
                <template v-for="meisai in packagerow.packagemeisais">
                  <td
                    :key="meisai.rowindex"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    :style="meisai.style"
                    :class="meisai.width"
                    :bgcolor="meisai.color"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <!-- <Btn
                    color="error"
                    v-if="showdelbtn(affiliation.index, packagerow.index, '1')"
                    text
                    small
                  ></Btn> -->
                  <!--<Btn
                    color="error"
                    v-if="showdelbtn(affiliation.index, packagerow.index, '2')"
                    disabled
                    text
                    small
                    @click="del(affiliation.index, packagerow.index, 'query')"
                    >削除</Btn
                  > -->
                </th>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
  </div>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
//import { VueGoodTable } from "vue-good-table";

import Api from "@/mixins/api";
import * as Dummy from "./dummy";
import ScheduleFixDialog from "./dialogs/ScheduleFixDialog.vue";
import AnytimeScheduleDialog from "./dialogs/AnytimeScheduleDialog.vue";
import ScheduleQuery from "@/models/ScheduleQuery";
import PackageinfoResponse from "@/models/PackageinfoResponse";

const ViewTypes = [{ text: "月", value: "month" }];

const Selects = {
  Cycle: 0,
  Any: 1
};

const RadioSelects = {
  Month: 0,
  point: 1
};

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];
export default {
  name: "ScheduleMaintenance",
  mixins: [Common, ShowDialogs, Api],
  components: {
    //"vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  data: () => ({
    menu: false,
    date:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateFrom: DATENOW,
    dateTo: DATENOW,
    select: Selects.Cycle,
    radioselect: RadioSelects.Month,
    records: [],
    isConfirm: false,
    attendances: { rows: [], columns: [] },
    summaries: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summaryheader: [],
    summary: [],
    users: Users,
    user: Users[0],
    Selects,
    RadioSelects,
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: [],
    shozokus: [],
    shozoku: { code: "", name: "" },
    backForCalc: false,
    backForShift: false,
    backForApprovalStatus: false,
    showConfirm: false,
    hospitals: [],
    selecthospital: { code: "", name: "" },
    schedule: [],
    workaffiliation: [],
    floor: "",
    area: "",
    workarea: ""
  }),
  computed: {
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  methods: {
    authcontrol() {
      let authority = this.$store.state.authority;
      if (authority == undefined || authority == null || authority === "") {
        authority = 0;
      }
      console.log("authority", authority);
      if (Number(authority) < 5) {
        return false;
      }
      return true;
    },
    showdelbtn(aindex, pindex, pattern) {
      const packs = this.affiliations[aindex].packages[pindex];
      if (this.radioselect != RadioSelects.Month) {
        // 月処理出なければ処理対象外
        return false;
      }

      // 月処理の場合は、以下の条件で表示を切替
      const returnflag = [true, false];
      if (packs[0].packrefkey != "") {
        returnflag[0] = false;
        returnflag[1] = true;
      }
      if (packs[0].packageNo < 0) {
        returnflag[0] = false;
        returnflag[1] = true;
      }
      if (packs[0].anytimeflag == "1") {
        returnflag[0] = false;
        returnflag[1] = true;
      }
      if (packs[0].futuremonth == "1") {
        returnflag[0] = false;
        returnflag[1] = true;
      }

      if (pattern == "1") {
        return returnflag[0];
      } else {
        return returnflag[1];
      }
    },
    rowStyleClass(row) {
      console.log("rowStyleClass", row);
      return "yellow";
      //return row.remarks === "1" ? "yellow" : "";
    },
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      this.isConfirm = await this.$confirm("よろしいですか？");
    },
    async click(pathparam) {
      console.log("click", pathparam);
      const date = new Date(this.date);
      const result = true;

      this.workaffiliation = [];

      /*if (this.radioselect == RadioSelects.point) {
        if (
          (this.floor == undefined || this.floor == "") &&
          (this.area == undefined || this.area == "") &&
          (this.workarea == undefined || this.workarea == "")
        ) {
          this.$error("フロア・エリア・作業箇所のいずれかを入力してください。");
          return;
        }
      }*/

      if (result) {
        this.$loading();
        try {
          const schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam,
            "0",
            ""
          );

          this.schedule = schedule;
          this.affiliations = [];
          this.affiliations = this.workaffiliation;

          console.log("schedule", this.schedule);
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async reference() {
      const date = new Date(this.date);

      try {
        const schedule = await this.getShiftMonth(
          date.getFullYear(),
          date.getMonth() + 1,
          "query",
          "1",
          "9"
        );

        //console.log("test", this.workaffiliation);

        if (this.workaffiliation.length == 0) {
          this.$warning(
            "選択中の年月での対象となる随時パッケージがありません。"
          );
          //this.$unloading();
          return;
        }

        const packs = [];
        packs.push({
          schedule: schedule,
          affiliations: this.workaffiliation
        });
        console.log(packs);

        const props = { args: packs };
        console.log(props);

        const result = await this.$showDialog(AnytimeScheduleDialog, props);

        try {
          this.$loading();

          const date = new Date(this.date);

          this.workaffiliation = [];

          const schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            "query",
            "0",
            ""
          );

          this.schedule = schedule;
          this.affiliations = [];
          this.affiliations = this.workaffiliation;
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }

        //console.log("result", result);
      } catch (e) {
        this.$error(e.message);
      }

      /*if (result) {
        //const date = new Date(this.date);
        try {
          const schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            "query"
          );
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }*/
    },
    async bulkInsert() {
      console.log("bulkinsert", "query");
      const date = new Date(this.date);
      const result = true;
      const errorflag = [false];
      const nextmonthyear = [0];
      const nextmonth = [0];

      //今日日付から換算して翌々月以降の場合は、一括作成出来ないようにしておく
      if (Number(DATE.getMonth() + 1) == 12) {
        //12月の場合は、翌月は1月になるので注意
        nextmonthyear[0] = DATE.getFullYear() + 1;
        nextmonth[0] = 1;
      } else {
        nextmonthyear[0] = DATE.getFullYear();
        nextmonth[0] = Number(DATE.getMonth() + 1);
      }

      // エラーのチェック
      if (date.getFullYear() > nextmonthyear[0]) {
        // 年が大きい時点で不可
        errorflag[0] = true;
      } else if (nextmonthyear[0] == date.getFullYear()) {
        // 年が同じ場合は、月を比較する
        if (Number(date.getMonth() + 1) > nextmonth[0] + 1) {
          // 月が大きい場合はエラー
          errorflag[0] = true;
        }
      }

      //エラーの場合は、個々で
      if (errorflag[0] == true) {
        this.$error("一括作成出来るのは、翌月までとなります。");
        return;
      }

      if (result) {
        this.$loading();
        try {
          const re = await this.$confirm(
            "スケジュールを一括で登録しますか？\n(作成済のデータは影響ありません。)",
            "確認"
          );
          if (!re) return;

          const param = new ScheduleQuery(
            this.selecthospital,
            date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2),
            "1",
            "0",
            "0"
          );

          // API送信
          const result = await this.$post(this.Paths.schedulebulkinsert, param);

          this.workaffiliation = [];

          //if (result) {
          //this.$loading();
          const schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            "query",
            "0",
            ""
          );

          this.schedule = schedule;
          this.affiliations = [];
          this.affiliations = this.workaffiliation;

          //console.log("schedule", this.schedule);
          //this.tableTitle = this.format;
          //}
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getShiftMonth(year, month, pathparam, select, zuiji) {
      console.log("getShiftMonth", year, month, pathparam);
      // 選択した所属を取得
      console.log("selected", this.selecthospital);
      this.workaffiliation = [];
      //this.packages.packagemeisais = [];
      //this.packages = [];
      //this.schedules = [];

      //検索処理対応
      const datef = new Date(this.dateFrom);
      const datet = new Date(this.dateTo);
      const datefy = datef.getFullYear();
      const datefm = datef.getMonth() + 1;
      const datety = datet.getFullYear();
      const datetm = datet.getMonth() + 1;

      const param = {
        affiliations: this.selecthospital,
        targetmonth: year + "-" + ("00" + month).slice(-2),
        scheduleflag: "1",
        unfinished: "0",
        select: select,
        pointflag: this.radioselect,
        floor: this.floor,
        area: this.area,
        workarea: this.workarea,
        targetmonthfrom: datefy + "-" + ("00" + datefm).slice(-2),
        targetmonthto: datety + "-" + ("00" + datetm).slice(-2)
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "予定データ取得");
      } else {
        return await this.setShiftCalendar(result, year, month, zuiji);
      }
    },
    async setShiftCalendar(apidata, year, month, zuiji) {
      console.log("setShiftCalendar", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;
      console.log("schedulehedder", shedder);

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        if (
          this.radioselect == RadioSelects.point &&
          shedder[i].name == "備考"
        ) {
          schedules.push({
            index: Number(shedder[i].code),
            name: "前回情報"
          });
        } else {
          schedules.push({
            index: Number(shedder[i].code),
            name: shedder[i].name
          });
        }
      }

      //
      const hedder = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        console.log("affiliations", this.affiliations);
        const rowscount = [0];
        const rowindex = [0];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          const bgcolor = [""];
          const cellstyle = ["white-space: pre-line; word-wrap: break-word;"];
          //行の色設定
          //作業日が入っていない場合は、色を付ける
          if (c.scheduledate == null || c.scheduledate == "") {
            //検索処理の場合は、色付けを行わない
            if (this.radioselect == RadioSelects.Month) {
              bgcolor[0] = "yellow";
            }
          }

          cellstyle[1] =
            "white-space: pre-line; word-wrap: break-word; color:#ff0000";

          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          const tanto = [""];
          const tantonm = [""];
          const wtanto = [""];
          const wtantonm = [""];

          //担当者のセット
          for (let d = 0; d < c.tanto.length; d++) {
            if (d > 0) {
              tanto[0] = tanto[0] + ",";
              tantonm[0] = tantonm[0] + ",\r\n";
            }
            tanto[0] = tanto[0] + c.tanto[d].userid;
            tantonm[0] = tantonm[0] + c.tanto[d].username;
          }

          //作業担当者のセット
          for (let e = 0; e < c.worktanto.length; e++) {
            if (e > 0) {
              wtanto[0] = wtanto[0] + ",";
              wtantonm[0] = wtantonm[0] + ",\r\n";
            }
            wtanto[0] = wtanto[0] + c.worktanto[e].userid;
            wtantonm[0] = wtantonm[0] + c.worktanto[e].username;
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const stime = c.startTime.split(":");
            const etime = c.endTime.split(":");

            //作業箇所の情報を取得する
            const warea = [""];
            const wareasuu = [""];
            const wareakbn = [""];
            const st = [""];
            const add = [""];
            const sep = [""];
            const key = [""];
            const ref = [""];
            const other = [""];
            const nextc = [""];
            const allncflag = [false];
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                wareasuu[0] = wareasuu[0] + ",";
                wareakbn[0] = wareakbn[0] + ",";
                st[0] = st[0] + ",";
                add[0] = add[0] + ",";
                sep[0] = sep[0] + ",";
                key[0] = key[0] + ",";
                ref[0] = ref[0] + ",";
                other[0] = other[0] + ",";
                nextc[0] = nextc[0] + ",";
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              wareasuu[0] = wareasuu[0] + s.workarea[l].workareaSuu;
              wareakbn[0] = wareakbn[0] + s.workarea[l].workareaSuuKbn;
              st[0] = st[0] + s.workarea[l].displayflag;
              add[0] = add[0] + s.workarea[l].addflag;
              sep[0] = sep[0] + s.workarea[l].sepalationflag;
              key[0] = key[0] + s.workarea[l].keyid;
              ref[0] = ref[0] + s.workarea[l].referenceworkareaid;
              other[0] = other[0] + s.workarea[l].processingscheduleother;
              if (
                s.workarea[l].processingschedulenextcycle != null &&
                s.workarea[l].processingschedulenextcycle != undefined
              ) {
                nextc[0] = nextc[0] + s.workarea[l].processingschedulenextcycle;

                if (
                  s.workarea[l].processingschedulenextcycle == "0" &&
                  allncflag[0] == true
                ) {
                  allncflag[0] = false;
                }
                //次回サイクルが設定されている場合は、色付けを中止
                /*if (s.workarea[l].processingschedulenextcycle == "1" &&  bgcolor[0] == "yellow") {
                   bgcolor[0] = "";
                }*/
              } else {
                nextc[0] = nextc[0] + "0";
              }
            }

            if (allncflag[0] == true && bgcolor[0] == "yellow") {
              bgcolor[0] = "";
            }

            // if (c.packageNo < 0) {
            //   bgcolor[0] = "PowderBlue";
            // }

            const prows = [0];

            const yearmonth = [];
            //年月の初期値を設定
            if (c.scmonthstr != null && c.scmonthstr != "") {
              yearmonth[0] = c.scmonthstr;
            } else {
              yearmonth[0] = year + "-" + ("00" + month).slice(-2);
            }

            if (k == 0) {
              prows[0] = meisairowspan;
            }

            //物件名～フロアまでは、固定
            //物件名
            if (j > 0 || k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: yearmonth[0],
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: rowscount[0],
                status: yearmonth[0],
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            }
            //作業年月
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduleMonth,
              width: "seven",
              color: bgcolor[0],
              style: cellstyle[0]
            });
            //委託先
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.company,
              width: "five",
              color: bgcolor[0],
              style: cellstyle[0]
            });
            //作業内容
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              width: "seven",
              color: bgcolor[0],
              style: cellstyle[0]
            });
            //作業日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduledate,
              width: "seven",
              color: bgcolor[0],
              style: c.ambiguous == "1" ? cellstyle[1] : cellstyle[0]
            });
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                width: "five",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                width: "five",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            }
            //作業担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: wtanto[0],
              sepalate: "",
              name: wtantonm[0],
              width: "six",
              color: bgcolor[0],
              style: cellstyle[0]
            });
            //担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: tanto[0],
              sepalate: "",
              name: tantonm[0],
              width: "six",
              color: bgcolor[0],
              style: cellstyle[0]
            });
            //フロア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.floor,
              width: "seven",
              color: bgcolor[0],
              style: cellstyle[0]
            });
            //エリア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.area,
              width: "seven",
              color: bgcolor[0],
              style: cellstyle[0]
            });

            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: st[0],
              code: add[0],
              sepalate: sep[0],
              name: warea[0],
              keyid: key[0],
              referenceid: ref[0],
              width: "workarea",
              color: bgcolor[0],
              other: other[0],
              suu: wareasuu[0],
              kbn: wareakbn[0],
              nextcycle: nextc[0],
              style: cellstyle[0]
            });
            //備考
            if (this.radioselect == RadioSelects.Month) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: c.biko,
                width: "fifteen",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: c.preexecutiondate + "\r\n" + c.prezan,
                width: "fifteen",
                color: bgcolor[0],
                style: cellstyle[0]
              });
            }

            //その他引渡が必要な項目
            packagemeisaiList.push({
              rowspan: 0,
              addflag: s.addflag,
              sepalationflag: s.sepalationflag,
              referencemeisaiid: "",
              processingschedulenextmonth: "",
              processingschedulehold: "",
              processingschedulenextcycle: "",
              width: "",
              color: bgcolor[0],
              weekday: c.weekday,
              ninsuu: c.ninsuu
            });

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan,
                unfinished: false,
                keyId: c.keyid,
                yotei: zuiji == "9" ? "9" : "",
                select: this.radioselect,
                ex: c.executiondate ? "1" : "0",
                addReason: c.addReason,
                refflag: c.refflag,
                hospitalTanto: c.hospitalTanto,
                remainreson: c.remainreson,
                scncflag: s.scncflag,
                changeFlag: c.changeFlag,
                hospitalremainbiko: c.hospitalremainbiko,
                changeReason: c.changeReason,
                tantoUpdateInfo: c.tantoUpdateInfo,
                tantoUpdateReason: c.tantoUpdateReason,
                point: this.radioselect == RadioSelects.point ? true : false,
                bikoflag: true,
                packrefkey: c.packrefkey,
                anytimeflag: c.anytimeflag,
                futuremonth: c.futuremonth
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0,
                unfinished: false,
                keyId: c.keyid,
                yotei: zuiji == "9" ? "9" : "",
                select: this.radioselect,
                ex: c.executiondate ? "1" : "0",
                addReason: c.addReason,
                refflag: c.refflag,
                hospitalTanto: c.hospitalTanto,
                remainreson: c.remainreson,
                scncflag: s.scncflag,
                changeFlag: c.changeFlag,
                hospitalremainbiko: c.hospitalremainbiko,
                changeReason: c.changeReason,
                tantoUpdateInfo: c.tantoUpdateInfo,
                tantoUpdateReason: c.tantoUpdateReason,
                point: this.radioselect == RadioSelects.point ? true : false,
                bikoflag: true,
                packrefkey: c.packrefkey,
                anytimeflag: c.anytimeflag,
                futuremonth: c.futuremonth
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
          console.log("packages", this.packages);
        }

        const scounts = packages.length;

        this.workaffiliation.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      //this.scount = this.packages.length;
      console.log("scount", this.scount);

      const schedule = schedules;
      console.log("affiliations", this.affiliations);

      return schedule;
    },
    async setUpdateData(pcks, aindex) {
      this.$loading();
      console.log("setUpdateData", pcks);
      const affilitations = this.affiliations;
      const schedules = this.schedule;

      this.affiliations = [];
      //this.packages = [];
      this.schedule = [];

      this.schedule = schedules;
      affilitations[aindex].packages = pcks;
      //this.affiliations[aindex].packages = pcks;
      this.affiliations = affilitations;
      //this.packages = pcks;

      this.$unloading();
    },
    async getData(api) {
      let data;
      switch (this.select) {
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
            this.affiliations = [];
            this.schedule = [];
          }
          break;
        }
      }
      //await this.setAttendanceDetails(data);
    },
    async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },
    async getRecordByOffice() {
      const param = {
        affiliationId: this.shozoku.code ? this.shozoku.code : this.shozoku,
        attendanceDateFrom: this.dateFrom,
        attendanceDateTo: this.dateTo
      };
      console.log("getRecordByOffice", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query-office=" + encode);
    },
    async fix(aindex, pindex, pathparam) {
      console.log("fix", aindex + "-" + pindex);
      const packs = this.affiliations[aindex].packages[pindex];
      console.log("packs", packs);

      // 実績日が登録済の場合は、予定の編集不可
      if (packs[0].ex != "0") {
        this.$error("既に実績が登録されています。\n予定の変更は出来ません。");
        return;
      }

      const props = { args: packs };
      console.log(props);
      const result = await this.$showDialog(ScheduleFixDialog, props);

      console.log("result", result);

      if (result) {
        try {
          this.$loading();

          const date = new Date(this.date);

          this.workaffiliation = [];

          const schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            "query",
            "0",
            ""
          );

          this.schedule = schedule;
          this.affiliations = [];
          this.affiliations = this.workaffiliation;
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async del(aindex, pindex, pathparam) {
      const packs = this.affiliations[aindex].packages[pindex];
      console.log("packs", packs);

      const dayflag = ["1"];
      const chkresult = [""];

      /*if (dayflag[0] === "1") {
        if (packs[0].packrefkey === "1") {
          dayflag[0] = "0";
        }
        if (packs[0].packageNo < 0) {
          dayflag[0] = "0";
        }
        if (packs[0].anytimeflag === "1") {
          dayflag[0] = "0";
        }

        if (dayflag[0] === "1") {
          if (packs[0].ex == "1") {
            await this.$error(
              "実績登録までされているので、予定日の削除はできません。"
            );
            return;
          }
          if (packs[0].refflag == "1") {
            await this.$error(
              "別の予定に参照されているため、予定日の削除はできません。"
            );
            return;
          }
          chkresult[0] = await this.$deleteConfirm(
            "予定日の削除をします。\nよろしいですか？ \n(予定日以外の内容は削除されません。)"
          );
        } else {
          
        }
      } */

      if (packs[0].ex == "1") {
        await this.$error(
          "実績登録までされているので、予定の削除はできません。"
        );
        return;
      }
      if (packs[0].refflag == "1") {
        await this.$error(
          "別の予定に参照されているため、予定の削除はできません。"
        );
        return;
      }
      chkresult[0] = await this.$deleteConfirm(
        "予定の削除をします。\nよろしいですか？ \n(注意：登録されている内容が全て削除されます。)"
      );

      if (chkresult[0]) {
        try {
          this.$loading();

          const PackageValue = [];

          PackageValue.push({
            packageNo: packs[0].packageNo,
            startTime: null,
            endTime: null,
            worktanto: null,
            tanto: null,
            packagemeisai: null,
            biko: null,
            scheduledate: null,
            executiondate: "",
            addplaceflag: null,
            addplace: null,
            remainflag: null,
            remainplace: null,
            remainreson: null,
            remainbiko: null,
            assemblytime: null,
            executionstarttime: null,
            executionendtime: null,
            leavetime: null,
            keyid: packs[0].keyId,
            serialno: null,
            insert: null,
            delflag: dayflag[0] === "1" ? "0" : "1"
          });

          const Packagehed = [];
          Packagehed.push({
            hojinCd: packs[0].hojinCd,
            hospitalCd: packs[0].hospitalCd,
            hospitalNm: null,
            yearMonth: null,
            details: PackageValue,
            loginId: this.$store.state.userincode
          });

          const param = new PackageinfoResponse(Packagehed);
          const result = await this.$post(this.Paths.scheduledel, param);

          console.log("result", result);

          await this.$info("削除しました。", "予定削除");

          try {
            const date = new Date(this.date);

            this.workaffiliation = [];

            const schedule = await this.getShiftMonth(
              date.getFullYear(),
              date.getMonth() + 1,
              "query",
              "0",
              ""
            );

            this.schedule = schedule;
            this.affiliations = [];
            this.affiliations = this.workaffiliation;
          } catch (e) {
            console.log(e);
            this.$error(e.message);
          } finally {
            this.$unloading();
          }
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    },
    async add() {
      const aff = [];
      const pack = [];
      const mei = [];
      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      for (let i = 0; i < 11; i++) {
        if (i == 0) {
          mei.push({
            rowspan: 0,
            status: year + "-" + ("00" + month).slice(-2),
            code: "",
            sepalate: "",
            name: ""
          });
        } else {
          mei.push({
            rowspan: 0,
            status: "",
            code: "",
            sepalate: "",
            name: ""
          });
        }
      }

      pack.push({
        hojinCd: "",
        hospitalCd: "",
        index: "",
        packageMeisaiNo: "",
        packageNo: "",
        packagemeisais: mei,
        rowindex: "",
        rowspan: ""
      });

      aff.push(pack);
      //const props = { args: aff};

      const result = await this.$showDialog(ScheduleFixDialog, aff);

      if (result) {
        this.$loading();

        try {
          this.workaffiliation = [];

          const schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            "query",
            "0",
            ""
          );

          this.schedule = schedule;
          this.affiliations = [];
          this.affiliations = this.workaffiliation;
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    }
  },
  async created() {
    console.log("created", this.date);

    this.$loading();

    try {
      ////await this.getUsers();
      //// await this.getShozoku();
      //this.setdate();
    } finally {
      this.$unloading();
    }

    this.hospitals = await this.getHospital();
    //this.select = Selects.Cycle;
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style></style>

<style lang="scss" scoped>
.office {
  table {
    //width: 99%;
    border-collapse: collapse;
    display: block;
  }
}
.four {
  width: 4%;
}
.five {
  width: 5%;
}
.six {
  width: 6%;
}
.seven {
  width: 7%;
}
.fifteen {
  width: 11%;
}
.workarea {
  width: 20%;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    width: 100%;
  }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}
.range-text {
  text-align: center;
  line-height: 50px;
  height: 50px;
}
</style>
